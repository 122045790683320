import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { ReplaySubject, Subject, filter, takeUntil } from "rxjs";
import { MsalBroadcastService, MsalService } from "@azure/msal-angular";
import {
  EventMessage,
  EventType,
  InteractionRequiredAuthError,
  RedirectRequest,
} from "@azure/msal-browser";
import { environment } from "../environments/environment";

declare const window: any;

@Component({
  selector: "cmqs-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  title = "CMQuoteService.WebApp";
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(
    private msalService: MsalService,
    private msalBroadcastService: MsalBroadcastService
  ) {
    this.msalService.initialize();
    let loginScopes = {
      scopes: [
        "openid",
        "profile",
        "User.Read",
        environment.api.accessTokenScopes[0],
      ],
    };

    let msalInstance = this.msalService.instance;

    const accounts = msalInstance.getAllAccounts();
    if (accounts.length > 0) {
      msalInstance.setActiveAccount(accounts[0]);
    }

    msalInstance.addEventCallback((event: any) => {
      if (
        event.eventType === EventType.LOGIN_SUCCESS &&
        event.payload.account
      ) {
        const account = event.payload.account;
        msalInstance.setActiveAccount(account);
      }
    });

    // handle auth redired/do all initial setup for msal
    msalInstance
      .handleRedirectPromise()
      .then((authResult) => {
        // Check if user signed in
        const account = msalInstance.getActiveAccount();
        if (!account) {
          // redirect anonymous user to login page
          msalInstance.loginRedirect(loginScopes);
        }
      })
      .catch((err) => {
        // TODO: Handle errors
        console.log(err);
      });
  }

  ngOnInit() {
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) =>
            msg.eventType === EventType.LOGIN_FAILURE ||
            msg.eventType === EventType.ACQUIRE_TOKEN_FAILURE
        ),
        takeUntil(this.destroyed$)
      )
      .subscribe((result: EventMessage) => {
        let msalInstance: any = this.msalService.instance;
        msalInstance["browserStorage"].clear();

        if (result.error instanceof InteractionRequiredAuthError) {
          let loginScopes = {
            scopes: [
              "openid",
              "profile",
              "User.Read",
              environment.api.accessTokenScopes[0],
            ],
          };
          msalInstance.acquireTokenRedirect(loginScopes);
        }
      });
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
