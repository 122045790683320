import { Component } from "@angular/core";

@Component({
  selector: "cmqs-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"],
})
export class FooterComponent {
  copyrightYear: string = new Date().getFullYear().toString();
}
