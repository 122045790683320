<main>
  <div #fixedTop class="fixed-top">
    <cmqs-header></cmqs-header>
  </div>
  <div #fixedBottom class="fixed-bottom">
    <cmqs-footer></cmqs-footer>
  </div>
  <div
    class="parts container bg-light2"
    style="margin-top: 90px; margin-bottom: 46px"
  >
    <router-outlet></router-outlet>
  </div>
</main>
