import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class VehicleService {
  constructor(private http: HttpClient) {}

  getVehicles = () => {
    return this.http.get(`${environment.api.baseAddress}/api/vehicles/all`);
  };
}
