import { StateService } from './../_services/state.service';
import { QuoteService } from "./../_services/quote.service";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Component, ViewChild } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { NgbModal, NgbModalOptions } from "@ng-bootstrap/ng-bootstrap";
import * as _ from "underscore";
import { VehicleService } from "../_services/vehicle.service";

@Component({
  selector: "cmqs-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class HomeComponent {
  @ViewChild("content") content: any;
  public isCollapsed = true;
  public message: string = "";
  public showLoader = false;
  private jsonData: any = [];
  public make: string = "";
  public model: string = "";
  public year: string = "";
  public engineMake: string = "";
  public engineModel: string = "";
  public application: string = "";
  public annualMileage: string = "";
  public customYears: string = "";
  public customMileage: string = "";
  public state: string = "";
  public lstMake: string[] = [];
  public lstModel: string[] = [];
  public lstYear: string[] = [];
  public lstEngineMake: string[] = [];
  public lstEngineModel: string[] = [];
  public lstApplication: string[] = [];
  public lstAnnualMileage: string[] = [];
  public lstStates: any[] = [];
  public quotes: any;
  public isEnabled: boolean = false;
  public hasCustom: boolean = false;
  public clientName: string = "";
  public branch: string = "";

  constructor(
    private quoteService: QuoteService,
    private modalService: NgbModal,
    private vehicleService: VehicleService,
    private stateService: StateService
  ) {
    this.showLoader = true;
    this.vehicleService.getVehicles().subscribe({
      next: (res: any) => {
        this.jsonData = res;
        this.setFilters();
        this.stateService.getStates().subscribe({
          next: (s: any) => {
            this.lstStates = s;
            this.showLoader = false;
          },
          error: (err: HttpErrorResponse) => {
            console.log(err);
            this.showLoader = false;
          },
        });
      },
      error: (err: HttpErrorResponse) => {
        console.log(err);
        this.showLoader = false;
      },
    });
  }

  onValueChange(item: any, e: any) {
    console.log("onValueChange :", item, e.target.value, e.target.id);
    let isModified: boolean = false;
    if (e.target.id.indexOf("yr1") > -1) {
      if (!item.old_value1yr) {
        item.old_value1yr = item.value1yr;
      }
      if (!item.required || +item.old_value1yr <= +e.target.value) {
        item.value1yr = e.target.value;
        isModified = true;
      } else {
        e.target.value = item.old_value1yr;
      }
    } else if (e.target.id.indexOf("yr3") > -1) {
      if (!item.old_value3yrs) {
        item.old_value3yrs = item.value3yrs;
      }
      if (!item.required ||  +item.old_value3yrs <= +e.target.value) {
        item.value3yrs = e.target.value;
        isModified = true;
      } else {
        e.target.value = item.old_value3yrs;
      }
    } else if (e.target.id.indexOf("yr5") > -1) {
      if (!item.old_value5yrs) {
        item.old_value5yrs = item.value5yrs;
      }
      if (!item.required || +item.old_value5yrs <= +e.target.value) {
        item.value5yrs = e.target.value;
        isModified = true;
      } else {
        e.target.value = item.old_value5yrs;
      }
    } else if (e.target.id.indexOf("yrcustom") > -1) {
      if (!item.old_valueCustomYear) {
        item.old_valueCustomYear = item.valueCustomYear;
      }
      if (!item.required || +item.old_valueCustomYear <= +e.target.value) {
        item.valueCustomYear = e.target.value;
        isModified = true;
      } else {
        e.target.value = item.old_valueCustomYear;
      }
    }
    if (isModified) {
      this.submitRequest();
    } else {
      this.message =
        "Count of service item can not be less than required minimum count. So, automatically restored value to minimum required count.";
      this.modalService.open(this.content, { centered: true });
    }
  }

  onChange(e: any) {
    if (e.target.name == "make") {
      this.quotes = null;
      let filteredData = this.getFilteredData();
      var filteredMake =
        _.uniq(_.pluck<any, string>(filteredData, "vehicleMake")) || [];
      if (filteredMake.length > 1 || filteredMake[0] != e.target.value) {
        this.resetSelection();
      }
    }
    this.setFilters();
  }

  resetSelection() {
    this.model = "";
    this.year = "";
    this.engineMake = "";
    this.engineModel = "";
    this.application = "";
    this.annualMileage = "";
    this.customYears = "";
    this.customMileage = "";
  }

  getFilteredData() {
    let properties = {};
    if (this.make) {
      properties = { ...properties, ...{ vehicleMake: this.make } };
    }
    if (this.model) {
      properties = { ...properties, ...{ vehicleModel: this.model } };
    }
    if (this.year) {
      properties = { ...properties, ...{ vehicleYear: +this.year } };
    }
    if (this.engineMake) {
      properties = { ...properties, ...{ engineMake: this.engineMake } };
    }
    if (this.engineModel) {
      properties = { ...properties, ...{ engineModel: this.engineModel } };
    }
    if (this.application) {
      properties = { ...properties, ...{ application: this.application } };
    }
    if (this.annualMileage) {
      properties = { ...properties, ...{ annualMileage: +this.annualMileage } };
    }
    var filteredData = _.where(this.jsonData, properties);
    return filteredData;
  }

  setFilters = () => {
    let filteredData = this.getFilteredData();
    this.lstMake =
      _.uniq(_.pluck<any, string>(this.jsonData, "vehicleMake")) || [];
    this.lstModel =
      _.sortBy(_.uniq(_.pluck<any, string>(filteredData, "vehicleModel"))) ||
      [];
    this.lstYear =
      _.sortBy(
        _.uniq(_.pluck<any, string>(filteredData, "vehicleYear"))
      ).reverse() || [];
    this.lstEngineMake =
      _.sortBy(_.uniq(_.pluck<any, string>(filteredData, "engineMake"))) || [];
    this.lstEngineModel =
      _.sortBy(_.uniq(_.pluck<any, string>(filteredData, "engineModel"))) || [];
    this.lstApplication =
      _.sortBy(_.uniq(_.pluck<any, string>(filteredData, "application"))) || [];
    this.lstAnnualMileage =
      _.sortBy(_.uniq(_.pluck<any, string>(filteredData, "annualMileage"))) ||
      [];

    if (this.lstMake.length == 1) {
      this.make = this.lstMake[0];
    }
    if (this.lstModel.length == 1) {
      this.model = this.lstModel[0];
    }
    if (this.lstYear.length == 1) {
      this.year = this.lstYear[0];
    }
    if (this.lstEngineMake.length == 1) {
      this.engineMake = this.lstEngineMake[0];
    }
    if (this.lstEngineModel.length == 1) {
      this.engineModel = this.lstEngineModel[0];
    }
    if (this.lstApplication.length == 1) {
      this.application = this.lstApplication[0];
    }
    if (this.lstAnnualMileage.length == 1) {
      this.annualMileage = this.lstAnnualMileage[0];
    }
  };

  submit() {
    if (!this.validate()) {
      return;
    }
    this.quotes = null;
    this.showLoader = true;
    this.submitRequest();
  }

  validate() {
    if (this.customMileage && !this.customYears) {
      this.message = "Please provide custom year to get the quotes.";
      this.modalService.open(this.content, { centered: true });
      return false;
    } else if (!this.customMileage && this.customYears) {
      this.message = "Please provide custom mileage to get the quotes.";
      this.modalService.open(this.content, { centered: true });
      return false;
    }
    return true;
  }

  submitRequest() {
    let payload = this.getSubmitPayload();
    this.quoteService.getQuotes(payload).subscribe((x: any) => {
      this.quotes = x;
      this.showLoader = false;
      this.hasCustom = +x.request.customNoOfYears > 0;
    });
  }

  onCollapseClick() {
    this.customMileage = "";
    this.customYears = "";
  }

  private getSubmitPayload() {
    let changedServiceItems: any = [];
    if (this.quotes && this.quotes.response) {
      changedServiceItems = _.chain(this.quotes.response)
        .filter(function (x) {
          return (
            (x.old_value1yr && x.old_value1yr != x.value1yr) ||
            (x.old_value3yrs && x.old_value3yrs != x.value3yrs) ||
            (x.old_value5yrs && x.old_value5yrs != x.value5yrs) ||
            (x.old_valueCustomYear &&
              x.old_valueCustomYear != x.valueCustomYear)
          );
        })
        .map(function (s) {
          var si = {
            serviceItemId: s.serviceItemId,
            value1yr:
              s.old_value1yr && s.old_value1yr != s.value1yr ? s.value1yr : -1,
            value3yrs:
              s.old_value3yrs && s.old_value3yrs != s.value3yrs
                ? s.value3yrs
                : -1,
            value5yrs:
              s.old_value5yrs && s.old_value5yrs != s.value5yrs
                ? s.value5yrs
                : -1,
            valueCustomYear:
              s.old_valueCustomYear &&
              s.old_valueCustomYear != s.valueCustomYear
                ? s.valueCustomYear
                : -1,
          };
          return si;
        })
        .value();
    }
    let payload = {
      vehicleMake: this.make,
      vehicleModel: this.model,
      vehicleYear: this.year,
      engineMake: this.engineMake,
      engineModel: this.engineModel,
      application: this.application,
      annualMileage: this.annualMileage,
      state: this.state,
      customNoOfYears:
        this.customYears == "" || this.customYears == "0"
          ? 0
          : this.customYears,
      customMileage:
        this.customMileage == "" || this.customMileage == "0"
          ? 0
          : this.customMileage,
      editedServiceItems: changedServiceItems,
      clientName: this.clientName,
      branch: this.branch,
      specName: `RushCare Maintenance Plans | ${this.year}-${this.make}-${this.model}-${this.engineMake}-${this.engineModel} ${this.application}`,
    };
    return payload;
  }

  clear() {
    this.make = "";
    this.quotes = null;
    this.resetSelection();
    this.setFilters();
  }

  generatePDF = () => {
    if (!this.validate()) {
      return;
    }
    let payload = this.getSubmitPayload();
    this.quotes = null;
    this.showLoader = true;
    this.quoteService.generatePDF(payload).subscribe({
      next: (d: any) => {
        var fileLink = document.createElement("a");
        fileLink.href = "data:application/pdf;base64," + d.file;
        var date = new Date();
        var timestamp =
          date.getFullYear() +
          ("0" + (date.getMonth() + 1)).slice(-2) +
          ("0" + date.getDate()).slice(-2) +
          ("0" + date.getHours()).slice(-2) +
          ("0" + date.getMinutes()).slice(-2) +
          ("0" + date.getSeconds()).slice(-2);
        fileLink.download = "Quote_" + timestamp + ".pdf";
        fileLink.click();
        this.closeModal();
        this.showLoader = false;
        this.quotes = d;
        this.hasCustom = +d.request.customNoOfYears > 0;
      },
      error: (err: HttpErrorResponse) => {
        console.log(err);
        this.showLoader = false;
      },
    });
  };

  openModal(modal: any) {
    let ngbModalOptions: NgbModalOptions = {
      backdrop: "static",
      keyboard: false,
      centered: true,
    };
    this.modalService.open(modal, ngbModalOptions);
  }

  closeModal() {
    this.clientName = "";
    this.branch = "";
    this.modalService.dismissAll();
  }

  goToLink(url: string) {
    window.open(url, "_blank");
    this.quoteService.logTruckNotListed().subscribe({});
  }
}
