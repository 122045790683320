export const environment = {
  production: true,
  azureAd: {
    authority: "__$AzureAd.Authority$__",
    clientId: "__$AzureAd.ClientId$__",
  },

  api:{
    accessTokenScopes: ["__$Api.AccessTokenScopes$__"],
    baseAddress: "__$Api.BaseAddress$__",
  },
};
