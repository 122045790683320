import {  Configuration } from '@azure/msal-browser';

import {environment} from "../environments/environment";


export const msalConfig: Configuration = {
  auth: {
    clientId: environment.azureAd.clientId,
    authority:  environment.azureAd.authority,
    redirectUri: '/',
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false
  }
}
