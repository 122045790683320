import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: 'root'
})
export class StateService {

  constructor(private http: HttpClient) {}

  getStates = () => {
    return this.http.get(`${environment.api.baseAddress}/api/states/all`);
  };
}
