<div class="container-fluid p-0">
  <nav class="navbar navbar-expand-lg navbar-dark bg-secondary">
    <div class="container p-0">
      <a href="/" class="navbar-brand">
        <img
          src="../../../assets/img/RTC Maintenance Plans_RTC Maintenance Plans Reverse Stacked.png"
          alt="Home page"
          class="logo-image"
        />
        <!-- <span class="fs-4 ps-3" style="font-family: TradeGothicLT-CondEighteen"
          >CMQuoteService</span
        > -->
      </a>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <!-- <div
        class="collapse navbar-collapse justify-content-end"
        id="navbarSupportedContent"
      >
        <ul class="navbar-nav">
          <li class="nav-item">
            <a class="nav-link active" aria-current="page" href="#">Home</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#">Course</a>
          </li>
        </ul>
      </div> -->
    </div>
  </nav>
</div>
