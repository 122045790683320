<form
  id="form_search"
  class="needs-validation"
  *ngIf="
    lstMake &&
    lstModel &&
    lstYear &&
    lstAnnualMileage &&
    lstApplication &&
    lstEngineMake &&
    lstEngineModel
  "
>
  <div class="container p-0">
    <div class="row">
      <div class="col-12 py-3 bg-white">
        <div class="title-of-box-hold">
          <h5 class="strong">Search</h5>
        </div>
        <div class="p-3">
          <div class="row">
            <div class="row pb-2">
              <div class="col-lg-2">
                <label for="make" class="form-label strong">Make</label>
                <select
                  class="form-control"
                  id="make"
                  name="make"
                  [(ngModel)]="make"
                  (change)="onChange($event)"
                >
                  <option value="">Select Make</option>
                  <option *ngFor="let m of lstMake" [value]="m">
                    {{ m }}
                  </option>
                </select>
              </div>
              <div class="col-lg-2">
                <label for="model" class="form-label strong">Model</label>
                <select
                  class="form-control"
                  id="model"
                  name="model"
                  [(ngModel)]="model"
                  (change)="onChange($event)"
                >
                  <option value="">Select Model</option>
                  <option *ngFor="let m of lstModel" [value]="m">
                    {{ m }}
                  </option>
                </select>
              </div>
              <div class="col-lg-2">
                <label for="year" class="form-label strong">Year</label>
                <select
                  class="form-control"
                  id="year"
                  name="year"
                  [(ngModel)]="year"
                  (change)="onChange($event)"
                >
                  <option value="">Select Year</option>
                  <option *ngFor="let y of lstYear" [value]="y">
                    {{ y }}
                  </option>
                </select>
              </div>
              <div class="col-lg-2">
                <label for="engineMake" class="form-label strong"
                  >Engine Make</label
                >
                <select
                  class="form-control"
                  id="engineMake"
                  name="engineMake"
                  [(ngModel)]="engineMake"
                  (change)="onChange($event)"
                >
                  <option value="">Select Engine Make</option>
                  <option *ngFor="let emk of lstEngineMake" [value]="emk">
                    {{ emk }}
                  </option>
                </select>
              </div>
              <div class="col-lg-2">
                <label for="engineModel" class="form-label strong"
                  >Engine Model</label
                >
                <select
                  class="form-control"
                  id="engineModel"
                  name="engineModel"
                  [(ngModel)]="engineModel"
                  (change)="onChange($event)"
                >
                  <option value="">Select Engine Model</option>
                  <option *ngFor="let emd of lstEngineModel" [value]="emd">
                    {{ emd }}
                  </option>
                </select>
              </div>
              <div class="col-lg-2">
                <label for="application" class="form-label strong"
                  >Application</label
                >
                <select
                  class="form-control"
                  id="application"
                  name="application"
                  [(ngModel)]="application"
                  (change)="onChange($event)"
                >
                  <option value="">Select Application</option>
                  <option *ngFor="let a of lstApplication" [value]="a">
                    {{ a }}
                  </option>
                </select>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-3">
                <label for="annualMileage" class="form-label strong"
                  >Standard Mileage</label
                >
                <select
                  class="form-control"
                  id="annualMileage"
                  name="annualMileage"
                  [(ngModel)]="annualMileage"
                  (change)="onChange($event)"
                >
                  <option value="">Select Annual Mileage</option>
                  <option *ngFor="let am of lstAnnualMileage" [value]="am">
                    {{ am }}
                  </option>
                </select>
              </div>

              <div class="col-lg-3">
                <label for="states" class="form-label strong"
                  >States</label
                >
                <select
                  class="form-control"
                  id="states"
                  name="states"
                  [(ngModel)]="state"
                  (change)="onChange($event)"
                >
                  <option value="">Select State</option>
                  <option *ngFor="let s of lstStates" [value]="s.code">
                    {{ s.code }} - {{ s.name }}
                  </option>
                </select>
              </div>

              <div class="col-lg-9">
                <div class="d-flex">
                  <div class="p-2">
                    <button
                      type="button"
                      class="mt-4 btn btn-link link-primary"
                      (click)="onCollapseClick(); collapse.toggle()"
                      [attr.aria-expanded]="!isCollapsed"
                      aria-controls="collapseExample"
                    >
                      Enter Custom Mileage
                    </button>
                  </div>
                  <div class="flex-grow-1">
                    <div>
                      <div
                        #collapse="ngbCollapse"
                        [(ngbCollapse)]="isCollapsed"
                        [horizontal]="true"
                      >
                        <div class="card m-0 border border-0">
                          <div class="card-body row p-0">
                            <div class="col-lg-6">
                              <label
                                for="customMileage"
                                class="form-label strong"
                                >Custom Mileage</label
                              >
                              <input
                                type="number"
                                class="form-control"
                                id="customMileage"
                                name="customMileage"
                                [(ngModel)]="customMileage"
                              />
                            </div>
                            <div class="col-lg-6">
                              <label for="customYears" class="form-label strong"
                                >Custom Years</label
                              >
                              <input
                                type="number"
                                class="form-control"
                                id="customYears"
                                name="customYears"
                                [(ngModel)]="customYears"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group d-flex justify-content-between">
              <div>&nbsp;</div>
              <div class="my-3">
                <button
                  id="search"
                  type="button"
                  class="btn btn-primary btn-lg"
                  (click)="submit()"
                  [disabled]="
                    !make ||
                    !model ||
                    !year ||
                    !engineMake ||
                    !engineModel ||
                    !application ||
                    !annualMileage
                  "
                >
                  Submit
                </button>
                &nbsp;
                <button
                  id="clear"
                  type="clear"
                  class="btn btn-secondary btn-lg"
                  (click)="clear()"
                >
                  Reset Selection
                </button>
                &nbsp;
                <a
                  type="button"
                  class="fs-6 link-primary strong"
                  (click)="
                    goToLink(
                      'https://app.smartsheet.com/b/form/40082476db4945b0a48353ea0c919085'
                    )
                  "
                  >Truck not listed</a
                >
              </div>
              <button
                id="generatePDF"
                type="button"
                class="btn btn-primary btn-lg my-3"
                [disabled]="
                  !make ||
                  !model ||
                  !year ||
                  !engineMake ||
                  !engineModel ||
                  !application ||
                  !annualMileage
                "
                (click)="openModal(otherInfo)"
              >
                Generate PDF
              </button>
            </div>
          </div>
        </div>
        <div *ngIf="quotes" class="row">
          <div class="col-12">
            <h3 class="strong">
              <span>
                RushCare Maintenance Plans |
                {{ quotes.request.vehicleYear }} {{
                  quotes.request.vehicleMake
                }} {{ quotes.request.vehicleModel }} {{
                  quotes.request.engineMake
                }} {{ quotes.request.engineModel }}
              </span>
              <span>
                {{ quotes.request.application }}
              </span>
            </h3>
          </div>

          <div class="col-12 pt-3">
            <table class="table table-striped table-hover">
              <ng-container *ngFor="let d of quotes.response">
                <ng-container [ngSwitch]="d.label">
                  <tr *ngSwitchCase="'Year'">
                    <th>&nbsp;</th>
                    <th *ngIf="+quotes.request.customNoOfYears > 0">
                      <div class="d-flex flex-column p-0">
                        <h4
                          class="position-relative strong w-100 m-0 text-center"
                        >
                          {{ d.valueCustomYear }}
                          {{ d.valueCustomYear > 1 ? "Years" : "Year" }}
                          <div
                            class="rounded-0 rounded-top position-absolute top-0 start-50 translate-middle badge bg-primary fs-5 strong recommended"
                          >
                            Custom
                          </div>
                        </h4>
                        <p class="text-center m-0">
                          {{ quotes.request.customMileage | number }} Miles Per
                          Year
                        </p>
                      </div>
                    </th>
                    <th>
                      <div class="d-flex flex-column">
                        <h4 class="strong text-center">
                          {{ d.value1yr }} Year
                        </h4>
                        <p class="text-center m-0">
                          {{ quotes.request.annualMileage | number }} Miles Per
                          Year
                        </p>
                      </div>
                    </th>
                    <th>
                      <div class="d-flex flex-column">
                        <h4 class="strong text-center">
                          {{ d.value3yrs }} Years
                        </h4>
                        <p class="text-center m-0">
                          {{ quotes.request.annualMileage | number }} Miles Per
                          Year
                        </p>
                      </div>
                    </th>
                    <th
                      [style]="
                        hasCustom ? 'border:1px solid #f0f0f0 !important;' : ''
                      "
                    >
                      <div class="d-flex flex-column p-0">
                        <h4
                          class="position-relative strong w-100 m-0 text-center"
                        >
                          {{ d.value5yrs }} Years
                          <div
                            class="rounded-0 rounded-top position-absolute top-0 start-50 translate-middle badge bg-primary fs-5 strong recommended"
                            *ngIf="!hasCustom"
                          >
                            Recommended
                          </div>
                        </h4>
                        <p class="text-center m-0">
                          {{ quotes.request.annualMileage | number }} Miles Per
                          Year
                        </p>
                      </div>
                    </th>
                  </tr>
                  <tr *ngSwitchCase="'MonthlyPrice'">
                    <td>
                      <h4 class="strong mb-0 text-end">Price Per Month</h4>
                    </td>
                    <td *ngIf="+quotes.request.customNoOfYears > 0">
                      <div class="d-flex justify-content-center">
                        <div class="d-flex justify-content-between w-100">
                          <h4 class="strong">$</h4>
                          <h4 class="strong">
                            {{ d.valueCustomYear | currency : " " : "symbol" }}
                          </h4>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div class="d-flex justify-content-center">
                        <div class="d-flex justify-content-between w-100">
                          <h4 class="strong">$</h4>
                          <h4 class="strong">
                            {{ d.value1yr | currency : " " : "symbol" }}
                          </h4>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div class="d-flex justify-content-center">
                        <div class="d-flex justify-content-between w-100">
                          <h4 class="strong">$</h4>
                          <h4 class="strong">
                            {{ d.value3yrs | currency : " " : "symbol" }}
                          </h4>
                        </div>
                      </div>
                    </td>
                    <td
                      [style]="
                        hasCustom ? 'border:1px solid #f0f0f0 !important;' : ''
                      "
                    >
                      <div class="d-flex justify-content-center">
                        <div class="d-flex justify-content-between w-100">
                          <h4 class="strong">$</h4>
                          <h4 class="strong">
                            {{ d.value5yrs | currency : " " : "symbol" }}
                          </h4>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr *ngSwitchCase="'PackagePrice'">
                    <td>
                      <h4 class="strong mb-0 text-end">
                        Prepaid Amount
                      </h4>
                    </td>
                    <td *ngIf="+quotes.request.customNoOfYears > 0">
                      <div class="d-flex justify-content-center">
                        <div class="d-flex justify-content-between w-100">
                          <h4 class="strong">$</h4>
                          <h4 class="strong">
                            {{ d.valueCustomYear | currency : " " : "symbol" }}
                          </h4>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div class="d-flex justify-content-center">
                        <div class="d-flex justify-content-between w-100">
                          <h4 class="strong">$</h4>
                          <h4 class="strong">
                            {{ d.value1yr | currency : " " : "symbol" }}
                          </h4>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div class="d-flex justify-content-center">
                        <div class="d-flex justify-content-between w-100">
                          <h4 class="strong">$</h4>
                          <h4 class="strong">
                            {{ d.value3yrs | currency : " " : "symbol" }}
                          </h4>
                        </div>
                      </div>
                    </td>
                    <td
                      [style]="
                        hasCustom ? 'border:1px solid #f0f0f0 !important;' : ''
                      "
                    >
                      <div class="d-flex justify-content-center">
                        <div class="d-flex justify-content-between w-100">
                          <h4 class="strong">$</h4>
                          <h4 class="strong">
                            {{ d.value5yrs | currency : " " : "symbol" }}
                          </h4>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr *ngSwitchDefault>
                    <td class="w-50">
                      <label class="form-label m-0 fs-5 strong">
                        {{ d.label }}
                      </label>
                    </td>

                    <td *ngIf="+quotes.request.customNoOfYears > 0">
                      <div class="d-flex justify-content-center">
                        <input
                          type="number"
                          class="form-control fs-5 strong text-center"
                          id="yrcustom-{{ d.vehicleId }}-{{ d.serviceItemId }}"
                          name="yrcustom-{{ d.vehicleId }}-{{
                            d.serviceItemId
                          }}"
                          [ngModel]="d.valueCustomYear"
                          placeholder="Count"
                          (change)="onValueChange(d, $event)"
                          [ngClass]="
                            d.old_valueCustomYear &&
                            d.old_valueCustomYear != d.valueCustomYear
                              ? 'highlight border-primary'
                              : ''
                          "
                        />
                      </div>
                    </td>
                    <td>
                      <div class="d-flex justify-content-center">
                        <input
                          type="number"
                          class="form-control fs-5 strong text-center"
                          id="yr1-{{ d.vehicleId }}-{{ d.serviceItemId }}"
                          name="yr1-{{ d.vehicleId }}-{{ d.serviceItemId }}"
                          [value]="d.value1yr"
                          placeholder="Count"
                          (change)="onValueChange(d, $event)"
                          [ngClass]="
                            d.old_value1yr && d.old_value1yr != d.value1yr
                              ? 'highlight border-primary'
                              : ''
                          "
                        />
                      </div>
                    </td>
                    <td>
                      <div class="d-flex justify-content-center">
                        <input
                          type="number"
                          class="form-control fs-5 strong text-center"
                          id="yr3-{{ d.vehicleId }}-{{ d.serviceItemId }}"
                          name="yr3-{{ d.vehicleId }}-{{ d.serviceItemId }}"
                          [ngModel]="d.value3yrs"
                          placeholder="Count"
                          (change)="onValueChange(d, $event)"
                          [ngClass]="
                            d.old_value3yrs && d.old_value3yrs != d.value3yrs
                              ? 'highlight border-primary'
                              : ''
                          "
                        />
                      </div>
                    </td>
                    <td
                      [style]="
                        hasCustom ? 'border:1px solid #f0f0f0 !important;' : ''
                      "
                    >
                      <div class="d-flex justify-content-center">
                        <input
                          type="number"
                          class="form-control fs-5 strong text-center"
                          id="yr5-{{ d.vehicleId }}-{{ d.serviceItemId }}"
                          name="yr5-{{ d.vehicleId }}-{{ d.serviceItemId }}"
                          [ngModel]="d.value5yrs"
                          placeholder="Count"
                          (change)="onValueChange(d, $event)"
                          [ngClass]="
                            d.old_value5yrs && d.old_value5yrs != d.value5yrs
                              ? 'highlight border-primary'
                              : ''
                          "
                        />
                      </div>
                    </td>
                  </tr>
                </ng-container>
              </ng-container>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>

<!-- Notification Modal -->
<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Notification</h4>
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    ></button>
  </div>
  <div class="modal-body">
    <p>
      {{ message }}
    </p>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-secondary"
      (click)="modal.close('Close click')"
    >
      Close
    </button>
  </div>
</ng-template>

<!-- Form Modal -->
<ng-template #otherInfo let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Other Info</h4>
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    ></button>
  </div>
  <div class="modal-body">
    <form id="form_search" class="needs-validation">
      <div class="container p-0">
        <div class="row">
          <div class="col-lg-6">
            <label for="clientName" class="form-label strong"
              >Client Name</label
            >
            <input
              type="text"
              class="form-control"
              id="clientName"
              name="clientName"
              [(ngModel)]="clientName"
            />
          </div>
          <div class="col-lg-6">
            <label for="branch" class="form-label strong">Branch</label>
            <input
              type="text"
              class="form-control"
              id="branch"
              name="branch"
              [(ngModel)]="branch"
            />
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="closeModal()">
      Cancel
    </button>
    &nbsp;
    <button
      id="generatePDF"
      type="button"
      class="btn btn-primary"
      (click)="generatePDF()"
      [disabled]="
        !make ||
        !model ||
        !year ||
        !engineMake ||
        !engineModel ||
        !application ||
        !annualMileage ||
        !clientName ||
        !branch
      "
    >
      Submit
    </button>
  </div>
</ng-template>

<!--  Loader-->
<div *ngIf="showLoader">
  <div id="backdrop"></div>
  <div class="loader">
    <div
      class="spinner-border"
      role="status"
      style="width: 5rem; height: 5rem"
    ></div>
    <br />
    <p class="fs-5 strong">Loading...</p>
  </div>
</div>
